body {
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

h4,
h5 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

p {
  font-size: 90%;
  width: calc(100% - 320px);
}

.price {
  position: fixed;
  right: 10px;
  top: 10px;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  background: white;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.3);
}
input,
textarea {
  width: 100%;
}
.deposit:before,
.start-payment:before,
.total-price:before {
  content: "$ ";
}

.year {
  user-select: none;
  width: calc(100% - 320px);
}

.month {
  display: inline-block;
  margin-right: 20px;
}
.days {
  display: grid;
  grid-template-columns: repeat(7, 24px);
  grid-gap: 1px;
  font-size: 12px;
}
.days > * {
  text-align: center;
  height: 18px;
}
.day {
  font-size: 14px;
  width: 24px;
  height: 18px;
  background: #eee;
  cursor: pointer;
}
.semi-holiday {
  background-color: #ff8888;
  color: white;
}
.holiday {
  background-color: red;
  color: white;
}
.booked {
  background-color: black;
  color: #eee;
}
.selected {
  background-color: mediumseagreen;
  color: white;
}

textarea {
  height: 6em;
}
@media (max-width: 600px) {
  p {
    width: 100%;
  }
  .year {
    width: 100%;
  }
  .price {
    position: static;
    padding: 0;
    margin-bottom: 3em;
    box-shadow: none;
    width: 100%;
  }
  .days {
    display: grid;
    grid-template-columns: repeat(7, 13vw);
    grid-gap: 1px;
    font-size: 12px;
  }
  .day {
    width: 100%;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
  }
  iframe {
    width: 100vw;
    height: calc(100vw * 9 / 16);
    margin-left: -8px;
  }
}
